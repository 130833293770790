<template>
    <v-modal v-model="input" class="w-full" max-width="2xl">
        <template v-slot:modal-header>
            Search Job
        </template>

        <template v-slot:default>
            <template v-if="isLoading">
                <v-loader />
            </template>

            <template v-else>
                <div>
                    <v-model-table ref="jobTable" v-bind="{ dataFunction: getUserJobs }" :query="query" :columns="columns" :show-toolbar="true">
                        <template v-slot:column_order_line_id="{ row }">
                            <v-link @click="assignToJob(row.order_line_id)">
                                {{ row.order_line_id }}
                                <i v-if="message.order_line_id === row.order_line_id" class="ml-2 fa fa-link text-secondary-500" />
                            </v-link>
                        </template>
                        <template v-slot:column_product_name="{ row }">
                            <v-text>
                                {{ row.product_name }}
                            </v-text>
                        </template>
                    </v-model-table>
                </div>
            </template>
        </template>

        <template v-slot:modal-footer="{ toggleModal }">
            <div />
            <div class="space-x-default">
                <v-button color="danger" :disabled="!message.order_line_id || isLoading" @click.stop="assignToJob(null)">
                    Remove Job
                </v-button>

                <v-button color="dark" @click.stop="toggleModal">
                    Close
                </v-button>
            </div>
        </template>
    </v-modal>
</template>
<script>
import MessageService from "@/services/modules/message-service";
import UserService from "@/services/modules/user-service";

export default {
    props: {
        value: {
            type: Boolean,
            required: false,
            default: () => true,
        },
        message: {
            type: Object,
            required: false,
            default: () => null,
        },
        userId: {
            type: [String, Number],
            required: false,
            default: () => null,
        },
    },
    data() {
        return {
            input: false,
            getUserJobs: (payload) => UserService.getJobs(this.userId, payload),
            query: {
                page: 1,
            },
            messageService: MessageService,
            columns: {
                order_line_id: {
                    label: "Job",
                    fullWidth: false,
                },
                primary_sherpa: {
                    label: "Primary Sherpa",
                    formatter: (row) => (row.primary_sherpa ? this.$options.filters.customerName(row.primary_sherpa.name) : ""),
                    fullWidth: false,
                    truncate: true,
                },
                product_name: {
                    label: "Product",
                    formatter: (row) => row.product_name,
                    fullWidth: true,
                    truncate: true,
                },
            },
        };
    },
    watch: {
        value: {
            handler(value) {
                this.input = value;
            },
            immediate: true,
        },
        input: {
            handler(value) {
                this.$emit("input", value);
            },
        },
    },
    methods: {
        assignToJob(jobId) {
            this.isLoading = true;
            this.messageService.assignToJob(this.message.message_id, { job_id: jobId })
                .finally(() => {
                    this.$emit("job-assigned");
                    this.isLoading = false;
                });
        },
    },
};
</script>
