<template>
    <v-section class="h-full flex flex-col">
        <template v-if="isLoading">
            <v-loader />
        </template>

        <template v-else>
            <v-section class="h-full flex flex-col">
                <v-section-heading v-if="conversation && conversation.user">
                    <template v-slot:default>
                        {{ conversation.user.user_id == $me.getUser().user_id ? 'Live Chat' : conversation.user.name }}
                    </template>

                    <template v-slot:actions>
                        <div v-if="$me.hasPermission('conversations.read.all') || (conversation.user_id && $me.hasPermission('users.read'))" class="flex-shrink-0 flex space-x-4 lg:space-x-0">
                            <div v-if="$me.hasPermission('conversations.read.all')" class="block lg:hidden">
                                <v-button color="dark" @click.stop="toggleSidebar">
                                    <i class="fa text-sm fa-lg fa-comments" />
                                </v-button>
                            </div>

                            <div v-if="conversation.user_id && $me.hasPermission('users.read')" class="flex-shrink-0">
                                <v-button color="dark" :to="{ name: 'users.show', params: { user: conversation.user_id } }">
                                    View User
                                </v-button>
                            </div>
                        </div>
                    </template>
                </v-section-heading>

                <div ref="messagesContainer" class="relative h-full min-h-0 overflow-y-auto">
                    <div v-if="messageForm.message_type_id && $me.hasPermission('messages.private.create')" :class="messageForm.message_type_id === 1 ? ' bg-primary-600 bg-opacity-90' : 'bg-light'" class="transition-colors z-30 px-default py-2 text-sm text-center text-white sticky top-0">
                        <template v-if="messageForm.message_type_id === 1">
                            Messaging with customer
                        </template>
                        <template v-else>
                            Adding a private note
                        </template>
                    </div>
                    <v-message-list v-if="conversation.user_id" :user-id="conversation.user_id" :messages="parsedMessages" @resend="resendMessage" />
                </div>

                <v-section class="p-default">
                    <v-form class="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-4 sm:items-center" @submit.prevent="sendMessage(messageForm)">
                        <div class="w-full flex flex-row-reverse space-x-reverse space-x-4 items-center sm:flex-row sm:space-x-4">
                            <v-message-type-toggle v-if="$me.hasPermission('messages.private.create')" :on="messageTypeToggle" @toggle="toggleMessageType" />
                            <v-form-input
                                v-model="messageForm.content"
                                :disabled="isDisabled || (messageForm.message_type_id === 1 ? !$me.hasPermission('messages.create') : !$me.hasPermission('messages.private.create'))"
                                type="text"
                                :placeholder="$me.hasPermission('messages.private.create') ? messageForm.message_type_id === 1 ? 'Write a message to the customer ...' : 'Adding a private note ...' : 'Send a message to your Sherpas ...'"
                                class="w-full"
                            />
                        </div>
                        <v-button :disabled="isDisabled || (messageForm.message_type_id === 1 ? !$me.hasPermission('messages.create') : !$me.hasPermission('messages.private.create'))" class="flex-shrink-0">
                            Send
                            <i class="ml-2 fa fa-paper-plane" />
                        </v-button>
                    </v-form>
                </v-section>
            </v-section>
        </template>
    </v-section>
</template>
<script>
import ConversationService from "@/services/modules/conversation-service";
import MessageList from "@/components/application/conversation/message-list.vue";
import MessageTypeToggle from "@/components/application/conversation/message-type-toggle.vue";
import EchoMixin from "@/mixins/echo";
import _ from "lodash";

export default {
    components: {
        "v-message-list": MessageList,
        "v-message-type-toggle": MessageTypeToggle,
    },
    mixins: [
        EchoMixin,
    ],
    props: {
        conversationId: {
            type: [String, Number],
            required: true,
        },
        toggleSidebar: {
            type: Function,
            required: false,
            default: () => ({}),
        },
    },
    data() {
        return {
            messageForm: {
                message_type_id: 1,
            },
            messageTypeToggle: true,
            messages: [],
            conversation: {
                user_id: "",
            },
            isDisabled: false,
            conversationService: ConversationService,
            canReadPrivate: false,
        };
    },
    computed: {
        parsedMessages() {
            return _.sortBy(this.messages, ["created_at", "DESC"]);
        },
    },
    watch: {
        conversationId: {
            handler(newValue, oldValue) {
                if (!oldValue || oldValue.toString() !== newValue.toString()) {
                    this.initialize(newValue);
                }
            },
            immediate: true,
            deep: true,
        },
        messages: {
            handler(value) {
                if (value.length > 0) {
                    this.scrollToLatest();
                }
            },
            immediate: true,
            deep: true,
        },
        messageTypeToggle: {
            handler(value) {
                if (value) {
                    this.messageForm.message_type_id = 1;
                } else {
                    this.messageForm.message_type_id = 2;
                }
            },
            immediate: true,
            deep: true,
        },
    },
    created() {
        this.canReadPrivate = this.$me.hasPermission("messages.private.read");

        this.echo.private(`Message-Channel-${this.conversationId}`)
            .listen("MessageCreated", (message) => {
                this.$nextTick(() => {
                    this.findAndReplaceMessageData(message);
                });
            })
            .listen("MessageUpdated", (message) => {
                this.$nextTick(() => {
                    this.findAndReplaceMessageData(message);
                });
            });

        if (this.canReadPrivate) {
            this.echo.private(`Private-Message-Channel-${this.conversationId}`)
                .listen("MessageCreated", (message) => {
                    this.$nextTick(() => {
                        this.findAndReplaceMessageData(message);
                    });
                })
                .listen("MessageUpdated", (message) => {
                    this.$nextTick(() => {
                        this.findAndReplaceMessageData(message);
                    });
                });
        }
    },
    beforeDestroy() {
        this.echo.leave(`Message-Channel-${this.conversationId}`);
        this.echo.leave(`Private-Message-Channel-${this.conversationId}`);
        this.echo.leaveChannel(`Message-Channel-${this.conversationId}`);
        this.echo.leaveChannel(`Private-Message-Channel-${this.conversationId}`);
    },
    methods: {
        initialize(id) {
            this.toggleLoading();

            this.conversationService.show(id)
                .then((response) => {
                    this.conversation = response.data.data;
                    this.$emit("selected-conversation-loaded", this.conversation);
                    return this.conversationService.getMessages(this.conversation.conversation_id);
                })
                .then((response) => {
                    if (response.data && response.data.data) {
                        this.messages = response.data.data;
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.toggleLoading();
                });
        },
        sendMessage(payload) {
            this.toggleDisable();

            const temporaryMessage = {
                message_id: -1,
                user_id: this.$me.getUser().user_id,
                user_name: this.$me.getUser().name,
                ...payload,
            };

            this.messages.push(temporaryMessage);

            this.conversationService.storeMessage(this.conversation.conversation_id, payload)
                .then((response) => {
                    const storedMessage = {
                        ...response.data.data,
                        user_name: response.data.data.user.name,
                        user_id: response.data.data.user.user_id,
                    };

                    this.$nextTick(() => {
                        const messageIndex = this.messages.findIndex((message) => message.message_id === -1 && message.content === storedMessage.content);

                        this.messages.splice(messageIndex, 1, storedMessage);
                        this.messageForm.content = null;
                    });
                })
                .catch(() => {
                    const messageIndex = this.messages.findIndex((message) => message.message_id === -1 && message.content === temporaryMessage.content);
                    this.messages.splice(messageIndex, 1, { ...temporaryMessage, is_failed: true, message_id: 0 });
                    this.messageForm.content = null;
                })
                .finally(() => {
                    this.toggleDisable();
                });
        },
        resendMessage({ payload = null, messageIndex = null }) {
            if (!payload || !messageIndex) return;

            this.messages.splice(messageIndex, 1);

            this.sendMessage({ ...payload, is_failed: null });
        },
        toggleMessageType(value) {
            this.messageTypeToggle = value;
        },
        scrollToLatest() {
            setTimeout(() => {
                if (this.$refs.messagesContainer && this.$refs.messagesContainer.scrollHeight) {
                    this.$refs.messagesContainer.scrollTop = this.$refs.messagesContainer.scrollHeight;
                }
            }, 300);
        },
        toggleDisable() {
            this.isDisabled = !this.isDisabled;
        },
        findAndReplaceMessageData(message) {
            const messageIndex = this.messages.findIndex((msg) => msg.user_id === message.user_id && msg.content === message.content);

            // Thank you for tipping! Please proceed to 'https://app.sherpasboosting.com/conversation-commands/tip' to complete your payment.
            console.log(message);
            // if (message.conversation_conmmand_id && content) {
            //     if (message.conversation_command_id === 1) {
            //         message.content = "Thank you for tipping! Please proceed to 'https://app.sherpasboosting.com/conversation-commands/tip' to complete your payment.";
            //     }
            // }

            if (messageIndex !== -1) {
                this.messages.splice(messageIndex, 1, message);
            } else {
                this.messages.push(message);
            }
        },
    },
};
</script>
