<template>
    <v-section-group class="w-full h-full flex flex-col items-stretch">
        <div v-if="messages && messages.length > 0" id="messageList" class="mt-auto p-default space-y-4">
            <template v-if="showTopLoader">
                <v-loader style="height: 5rem;" />
            </template>

            <template v-for="(message, messageIndex) in messages">
                <div :key="'message_' + messageIndex" :class="message.user_id == $me.getUser().user_id ? 'items-end' : 'items-start'" class="w-full flex flex-col text-gray-400">
                    <div class="max-w-3/4 min-w-3/4 sm:max-w-none sm:min-w-1/4">
                        <div :class="message.message_type_id === 2 ? 'bg-gray-600 bg-opacity-40' : message.user_id == $me.getUser().user_id && !message.is_system ? 'bg-primary-600 bg-opacity-40' : 'bg-light'" class="w-full text-sm relative max-w-xl inline-block shadow px-4 py-6 rounded-lg sm:px-6">
                            <div class="flex justify-between items-baseline">
                                <h3 class="capitalize font-medium truncate">
                                    <span :class="message.user_id == $me.getUser().user_id && message.message_type_id === 2 ? 'text-gray-100' : 'text-gray-400'">{{ message.is_system ? "System" : message.user_name }}</span>
                                </h3>
                                <div class="flex space-x-3 items-center">
                                    <p :class="message.user_id == $me.getUser().user_id || message.message_type_id === 2 ? 'text-gray-400' : 'text-gray-500'" class="mt-1 whitespace-nowrap sm:mt-0 sm:ml-3">
                                        <template v-if="message.created_at">
                                            <time class="italic">
                                                {{ message.created_at | dateToHuman }}
                                            </time>
                                        </template>
                                    </p>
                                </div>
                            </div>
                            <div :class="message.user_id == $me.getUser().user_id || message.message_type_id === 2 ? 'text-gray-300' : 'text-gray-300'" class="mt-4 space-y-6">
                                <template v-if="message.content">
                                    <span v-html="parseMessageLinks(message.content)" />
                                </template>

                                <template v-if="message.is_failed">
                                    <div class="mt-5 text-secondary-500 font-medium text-right">
                                        Sending failed. Click to retry.

                                        <div class="absolute cursor-pointer w-full h-full inset-0" @click.stop="$emit('resend', { payload: message, messageIndex })" />
                                    </div>
                                </template>
                            </div>
                        </div>

                        <div class="mt-1 text-gray-500 text-right">
                            <div class="flex flex-col items-end justify-center">
                                <template v-if="!message.created_at && !message.is_failed">
                                    <div>
                                        <svg class="animate-spin inline-block h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
                                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                                        </svg>
                                    </div>
                                </template>
                                <template v-else-if="message.is_failed">
                                    <div>
                                        <i v-tooltip="'Sending Failed'" class="fa fa-exclamation-circle text-secondary-500" />
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="flex items-center space-x-3">
                                        <span>
                                            <template v-if="message.message_type_id === 1">
                                                <i v-tooltip="'Customer Reply'" class="fa fa-reply" />
                                            </template>
                                            <template v-else>
                                                <i v-tooltip="'Private Note'" class="fa fa-sticky-note" />
                                            </template>
                                        </span>

                                        <template v-if="message.channel_id === 1">
                                            <i v-tooltip="'Email'" class="fa fa-envelope" />
                                        </template>
                                        <template v-if="message.channel_id === 2">
                                            <i v-tooltip="'SMS'" class="fa fa-sms" />
                                        </template>

                                        <template v-if="message.created_at">
                                            <i v-tooltip="'Sent'" class="fa fa-check-circle" />
                                        </template>

                                        <span v-if="$me.hasPermission('messages.update')" class="cursor-pointer" @click="toggleJobModal(message)">
                                            <template v-if="message.job_meta && message.job_meta.job_id">
                                                <i v-tooltip="`#${message.job_meta.job_id} ${message.job_meta.product} ${`${message.job_meta.primary_sherpa ? `(${message.job_meta.primary_sherpa})` : ''}`}`" class="fa fa-link text-secondary-500" />
                                            </template>
                                            <template v-else>
                                                <i v-tooltip="'No Job Link'" class="fa fa-unlink text-red-700" />
                                            </template>
                                        </span>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <v-job-assignment-modal v-model="jobModal" :user-id="userId" :message="selectedMessage" @job-assigned="toggleJobModal" />
        </div>

        <template v-else>
            <div class="w-full h-full flex flex-col items-center justify-center text-center">
                <i class="fas fa-comments fa-2x mb-4 text-gray-300" />
                <v-text>
                    Pending first message ...
                </v-text>
            </div>
        </template>
    </v-section-group>
</template>

<script>
import JobAssignmentModal from "../job/job-assignment-modal.vue";

export default {
    components: {
        "v-job-assignment-modal": JobAssignmentModal,
    },
    props: {
        messages: {
            type: Array,
            required: false,
            default: () => [],
        },
        userId: {
            type: [String, Number],
            required: true,
        },
        showTopLoader: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            jobModal: false,
            selectedMessage: null,
            selectedJob: null,
        };
    },
    methods: {
        toggleJobModal(message) {
            this.jobModal = !this.jobModal;

            if (this.jobModal === true) {
                this.selectedMessage = message;
            }
        },
        parseMessageLinks(content) {
            return content.replace(/(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*))/g, (x) => `<a class="text-primary-500 hover:text-primary-600 underline font-medium" target="_blank" href="${ x }">${ x }</a>`);
        },
    },
};
</script>
