<template>
    <button
        type="button"
        class="relative inline-flex items-center flex-shrink-0 h-6 w-12 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-primary-500"
        role="switch"
        :aria-checked="on"
        :class="on ? 'bg-primary-600' : 'bg-light'"
        @click="$emit('toggle', !(on))"
    >
        <span class="sr-only">Use setting</span>
        <span
            class="pointer-events-none relative inline-block h-8 w-8 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 translate-x-0"
            :class="on ? 'translate-x-4' : '-translate-x-1'"
        >
            <span
                class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity ease-in duration-200"
                aria-hidden="true"
                :class="on ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200'"
            >
                <i class="fa fa-sticky-note text-gray-600" />
            </span>
            <span
                class="h-full w-full flex items-center justify-center transition-opacity ease-out duration-100"
                aria-hidden="true"
                :class="on ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100'"
            >
                <i class="fa fa-reply text-primary-600" />
            </span>
        </span>
    </button>
</template>

<script>
export default {
    props: {
        on: {
            type: Boolean,
            required: false,
            default: () => false,
        },
    },
};
</script>
